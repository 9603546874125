var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "auth-header tui tuim" },
    [
      _c("t-logo-svg", { staticClass: "logo" }),
      _vm.description
        ? _c("h2", {
            staticClass: "description",
            domProps: { innerHTML: _vm._s(_vm.description) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
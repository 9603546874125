<template>
  <header class="auth-header tui tuim">
    <t-logo-svg class="logo" />
    <h2 class="description" v-if="description" v-html="description" />
  </header>
</template>

<script>
import TLogoSvg from '@/components/header/logo.vue'

export default {
  name: 'TAuthHeader',
  props: {
    description: {
      type: String,
      default: () => `Bem vindo ao <strong>Trackmob Collect - Beta</strong>, a captação de recursos que rende mais.`
    }
  },
  components: {
    TLogoSvg
  }
}
</script>

<style lang="scss">
.auth-header {
  margin-bottom: 20px;
  .description {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: $black-0;

    strong {
      font-weight: 800;
    }
  }
}
</style>
